import { IExternalLinks } from 'interfaces/external-links'
import { IProductPage } from 'interfaces/product-page'

export const getExternalLinksOfProduct = (productPage: IProductPage): IExternalLinks => {
    if (productPage === null || productPage === undefined) {
        return {
            hasExternalLink: false,
        }
    }

    const hasExternalLink = [
        productPage.amazonCollectionUrl,
        productPage.targetCollectionUrl,
        productPage.targetStarterSetUrl,
        productPage.targetUrlRefill,
        productPage.amazonStarterSetUrl,
        productPage.amazonUrlRefill,
    ].some((url) => !!url)

    return {
        hasExternalLink,
        amazonCollectionUrl: productPage.amazonCollectionUrl,
        targetCollectionUrl: productPage.targetCollectionUrl,
        targetStarterSetUrl: productPage.targetStarterSetUrl,
        targetUrlRefill: productPage.targetUrlRefill,
        amazonStarterSetUrl: productPage.amazonStarterSetUrl,
        amazonUrlRefill: productPage.amazonUrlRefill,
    }
}
