import { IMedicineLineItem } from 'lib/cart'
import { JAR_PILL_VARIANT_REGEXP, POUCH_VARIANT_REGEXP } from 'lib/util/sku-querying'

export interface IPlasticQuantity {
    lbs: number
    oz: number
}

/**
 * Assumptions
 *
 *   1. For every medicine (aka buying a jar) replaced, there's an average of 5 pouch refills per year
 *   2. Since our pouches are smaller, they only account for 0.5 of a normal industry sized bottle.
 */
const AVG_PLASTIC_BOTTLE_WEIGHT_IN_OZ = 3.75
const AVG_NUM_REFILLS_PER_YEAR_CABINET_SYSTEM = 5
const POUCH_TO_BOTTLE_RATIO = 0.85
export const OZ_IN_LBS = 16

/**
 * Calculates plastic savings of product
 * @param jarCount number
 * @param pouchCount number
 * @returns IPlasticQuantity
 */
export const getPlasticSavingsPerProduct = (jarCount: number, pouchCount: number): IPlasticQuantity => {
    let ozOfPlasticSaved = 0

    /**
     * Calculate the total ozs of plastic this order saves
     */
    // 1. Jar contribution
    ozOfPlasticSaved += jarCount * POUCH_TO_BOTTLE_RATIO * AVG_PLASTIC_BOTTLE_WEIGHT_IN_OZ
    // 2. Add on pouches contribution
    ozOfPlasticSaved += pouchCount * POUCH_TO_BOTTLE_RATIO * AVG_PLASTIC_BOTTLE_WEIGHT_IN_OZ

    // Round the oz to a whole number
    ozOfPlasticSaved = Math.round(ozOfPlasticSaved)

    /**
     * Break down total ozs to lbs and remainder oz
     */
    return {
        lbs: Math.floor(ozOfPlasticSaved / OZ_IN_LBS),
        oz: ozOfPlasticSaved % OZ_IN_LBS,
    }
}

/**
 * Returns plastic savings for given jar and pouch count
 * @param jarCount number
 * @param pouchCount number
 * @returns IPlasticSavings
 */
export const getPlasticYearlySavings = (jarCount: number, pouchCount: number): IPlasticQuantity => {
    let ozOfPlasticSaved = 0

    /**
     * Calculate the total ozs of plastic this order saves
     */
    // 1. Jar contribution
    ozOfPlasticSaved +=
        jarCount * AVG_NUM_REFILLS_PER_YEAR_CABINET_SYSTEM * POUCH_TO_BOTTLE_RATIO * AVG_PLASTIC_BOTTLE_WEIGHT_IN_OZ
    // 2. Add on pouches contribution
    ozOfPlasticSaved += pouchCount * POUCH_TO_BOTTLE_RATIO * AVG_PLASTIC_BOTTLE_WEIGHT_IN_OZ

    // Round the oz to a whole number
    ozOfPlasticSaved = Math.round(ozOfPlasticSaved)

    /**
     * Break down total ozs to lbs and remainder oz
     */
    return {
        lbs: Math.floor(ozOfPlasticSaved / OZ_IN_LBS),
        oz: ozOfPlasticSaved % OZ_IN_LBS,
    }
}

/**
 * Given a Cart, how much plastic will this order save over the given period?
 *
 * To note, this is a somewhat simplified model and assumes all medicines have the same usage pattern.
 * Future research should explore the periodicity per product and compute savings off of the specific items in the cart.
 *
 * For example, 81mg Aspirin is likely used every day, but lactose enzyme is used as needed.
 *
 * Perhaps a middle ground model is to classify each product as daily periodic use vs. random use.
 *
 * Reference: https://hpi.georgetown.edu/rxdrugs/#:~:text=Annual average out-of-pocket,more (see Figure 4)
 *
 * @param lineItems
 */
export const getPlasticYearlySavingsForOrder = (lineItems: IMedicineLineItem[]): IPlasticQuantity => {
    let jarCount = 0,
        pouchCount = 0

    /**
     * Aggregate pouchs and jars across the order
     */
    for (const lineItem of lineItems) {
        if (JAR_PILL_VARIANT_REGEXP.exec(lineItem.sku)) {
            jarCount = jarCount + lineItem.quantity
        } else if (POUCH_VARIANT_REGEXP.exec(lineItem.sku)) {
            pouchCount = pouchCount + lineItem.quantity
        }
    }

    return getPlasticYearlySavings(jarCount, pouchCount)
}

/**
 * Calculates pastic saved for an order
 * @param lineItems ILineItem
 * @returns IPlasticQuantity
 */
export const getPlasticSavingsForOrder = (lineItems: IMedicineLineItem[]): IPlasticQuantity => {
    let jarCount = 0,
        pouchCount = 0

    /**
     * Aggregate pouchs and jars across the order
     */
    for (const lineItem of lineItems) {
        if (JAR_PILL_VARIANT_REGEXP.exec(lineItem.sku)) {
            jarCount = jarCount + lineItem.quantity
        } else if (POUCH_VARIANT_REGEXP.exec(lineItem.sku)) {
            pouchCount = pouchCount + lineItem.quantity
        }
    }

    return getPlasticSavingsPerProduct(jarCount, pouchCount)
}

/**
 * Returns plastic consumed in lbs and oz
 * by industry meducines
 * @param bottleCount number
 * @returns IPlasticConsumption
 */
export const getPlasticConsumptionOfIndustry = (bottleCount: number): IPlasticQuantity => {
    /**
     * Assumptions
     *
     *   1. Purchasing 1 jar will net 1lb
     */
    const AVG_WEIGHT_OF_INDUSTRY_BOTTLE_IN_OZ = 16 // 1lb
    const OZ_IN_LBS = 16

    let ozOfPlasticConsumed = bottleCount * AVG_WEIGHT_OF_INDUSTRY_BOTTLE_IN_OZ

    // round oz to whole number
    ozOfPlasticConsumed = Math.round(ozOfPlasticConsumed)

    return {
        lbs: Math.floor(ozOfPlasticConsumed / OZ_IN_LBS),
        oz: ozOfPlasticConsumed % OZ_IN_LBS,
    }
}

/**
 * Returns plastic consumed in lbs and oz
 * by cabinet medicines
 * @param jarCount number
 * @returns IPlasticConsumption
 */
export const getPlasticConsumptionOfCabinet = (jarCount: number): IPlasticQuantity => {
    /**
     * Assumptions
     *
     *   1. Purchasing 1 jar will net 1lb
     */
    const AVG_WEIGHT_OF_JAR_IN_OZ = 0.75 // 1lb
    const OZ_IN_LBS = 16

    const ozOfPlasticConsumed = jarCount * AVG_WEIGHT_OF_JAR_IN_OZ

    return {
        lbs: Math.floor(ozOfPlasticConsumed / OZ_IN_LBS),
        oz: ozOfPlasticConsumed % OZ_IN_LBS,
    }
}

/**
 * Returns plastic savings for given jar and pouch count and age
 * @param jarCount number
 * @param pouchCount number
 * @returns IPlasticSavings
 */
export const getTotalPlasticSavings = (
    age: number,
    jarCount: number,
    pouchCount: number,
    estimatedLifespan: number,
): number => {
    const remainingYears = Math.max(estimatedLifespan - age, 1)
    const yearlySavings = getPlasticYearlySavings(jarCount, pouchCount)
    const totalSavingsInOz = (yearlySavings.lbs * OZ_IN_LBS + yearlySavings.oz) * remainingYears

    // Convert total oz to lbs
    const totalSavingsInLbs = totalSavingsInOz / OZ_IN_LBS

    return totalSavingsInLbs
}
